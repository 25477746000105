:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #048c88 !important;
  --primary_light: #8afff7;
  --primary_lighter: #d5f2f0;
  --primary_dark: #064e4a;
  --secondary: #49ae46;
  --secondary_light: #7fd67c;
  --secondary_dark: #2e9a2b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #e2ebea;
  --dark: #343a40;
}



.site-header-without-login {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
  padding: 10px;
  height: 100px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 5px #ccc;
}

img.site-logo {
  max-height: 80px;
}

.login-content {
  padding: 200px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.login-content h1 {
  font-size: 46px;
  padding-bottom: 50px;
  margin: 0px;
}



.login-content h3 {
  font-size: 26px;
  padding-bottom: 0px;
}

.login-content h4 {
  font-size: 26px;
  padding-bottom: 0px;
  text-align: center;
  padding-bottom: 50px;
}

.form-loader .text-center {
  position: absolute;
  top: 50%;
  margin-top: -6px;
}
#form-btn {
  max-width: 650px;
}
#form-btn {
  margin-right: 0px !important;
}

.custom-height-scrollbar {
  overflow-x: auto;
}

.left-content {
  color: var(--white);
}

.left-content p {
  line-height: 35px;
}

.form_row_single .MuiGrid-container .MuiGrid-item,
.modalPopup .MuiGrid-container .MuiGrid-item {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Adjust this value to change the gap size */
}

.field-wrapper {
  flex: 1;
  width: 40rem;
}

.dialogue-box {
  padding: 20px;
  overflow: hidden;
}

.popupcancelbtn {
  text-decoration: none !important;
  color: #000 !important;
  font-size: 15px;
  display: flex;
  justify-content: end;
  padding-top: 10px;
  cursor: pointer;
}

.popupcancelbtn:hover {
  color: #000 !important; /* Ensures the color stays the same on hover */
  text-decoration: none !important; /* Ensures no text decoration on hover */
}
.nav-link.loginBtn {
  padding: 0px;
  width: 100%;
  background: var(--white);
  text-align: center;
  margin-top: 3rem;
}
.loginBtn button {
  width: 100%;
  padding: 8px 22px;
}
.form-loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10;
}
.modalPopup h2 {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  background-color: var(--primary_lighter);
}

.loading_text {
  font-size: 14px !important;
  color: #000 !important;
}
.form-group.field {
  margin-bottom: 0px;
}
label.form-group.field {
  margin-bottom: 5px;
  display: none;
}
.deal_form .rjsf .form-group.field-object.customWrapper {
  float: left;
  width: 66.66%;
  margin-right: 20px;
}
.deal_form .rjsf .form-group.customWrapper .col-md-3 {
  flex: 0 0 50%;
  max-width: 50%;
  margin: 0px !important;
}
.deal_form_loan_strat_report .rjsf .form-group.field-object.customWrapper {
  float: left;
  width: calc(100% - 250px);
  margin-right: 20px;
}
.deal_form_loan_strat_report .rjsf .form-group.customWrapper .col-md-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin: 0px !important;
}
.form-group.field {
  margin-bottom: 0px;
}
.login-sign_up-links {
  text-decoration: none !important;
  /* color: #048c88; */
  color: #000;
  font-size: 15px;
  /* font-weight: 600; */
  display: flex;
justify-content: end;
padding-top: 10px;

}
#mainContent > .container-fluid {
  min-height: 100%;
}
#mainContent > .container-fluid .custom-height {
  height: calc(100vh - 100px);
}
.site-header-without-login + .container-fluid {
  background: var(--white);
}