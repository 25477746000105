#xyz {
    background-color: #000 !important;
    opacity: 1;
    position: absolute !important;
    padding: 15px 25px !important;
    border-radius: 10px !important;
    /* z-index: 99; */
    padding: 3px 10px 8px !important;
    /* z-index: 99; */
    text-align: center;
    border-radius: 10px;
    margin-top: 3px;
    white-space: nowrap;
    pointer-events: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* color: #757575; */
    position: relative;
    /* right: 0.8rem; */
  }
  
  .xyz {
    /* z-index: 1000 !important; */
  }
  #xyz p {
    color: white;
    font-family: ProximaNovaB;
  }
  #xyz button {
    margin-right: 10px;
    background-color: #00716e !important;
    font-family: ProximaNovaB;
  }
  #xyz input {
    border: none;
    background-color: transparent;
    font-family: ProximaNovaR;
    font-size: 14px;
    color: white;
    outline: none;
    /* overflow: auto; */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center !important;
    font-family: ProximaNovaS;
  }
  
  /* .net{
    color:#91e1de48
  } */
  
  /* HighlightCoords */
  .checkicon {
    background-color: #048c88;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    transition: all 0.3s ease;
  }
  .crossicon {
    margin-right: 8px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 14px;
    transition: all 0.3s ease;
    /* padding: 17px; */
    /* background-color: white; */
  }
  .checkicon:hover,
  .crossicon:hover {
    border-radius: 50%;
    cursor: pointer;
  }
  .logical-position {
    background-color: green;
    position: absolute !important;
    font-size: 8px;
  }
  
  /* react-pdf-highlightion */
  
  .pdfHighlighterSidebar {
    overflow: auto;
    color: rgb(119, 119, 119);
    background: linear-gradient(
      rgb(249, 248, 247),
      rgb(251, 250, 248) 46px,
      rgb(251, 251, 249) 120px,
      rgb(248, 247, 245) 35%,
      rgb(249, 248, 246)
    );
    width: 50vw;
    height: 100vh;
    overflow-y: scroll;
  }
  
  .pdfHighlighterSidebar__highlights {
    list-style: 'none';
    padding: 0;
  }
  
  .highlight__location {
    margin-top: 0.5rem;
    text-align: right;
    font-size: 10px;
  }
  
  .highlight__image {
    overflow: auto;
    max-width: 300px;
    border: 1px dashed;
  }
  
  .pdfHighlighterSidebar__highlight {
    padding: 1rem;
    cursor: pointer;
    transition: background 140ms ease-in;
    border-bottom: 1px solid rgb(119, 119, 119);
  }
  
  .pdfHighlighterSidebar__highlight:hover {
    background: rgba(58, 56, 52, 0.08);
  }
  
  a {
    color: #d35400;
  }
  
  blockquote {
    padding: 0;
    margin: 0;
    quotes: '\201c''\201d';
  }
  blockquote:before {
    content: open-quote;
  }
  blockquote:after {
    content: close-quote;
  }
  /* Spinner */
  .sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  
  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  .Highlight__part,
  ::selection {
    background: #95fffb !important;
  }
  .Highlight--scrolledTo .Highlight__part,
  .AreaHighlight__part {
    background: #00aba5 !important;
  }
  
  /* Tip Styles */
  .Tip__card > div > div {
    display: none !important;
  }
  